import React, { useState }  from "react";
import * as Icon from 'react-bootstrap-icons';
import MarkdownIt from 'markdown-it';
import TurndownService from 'turndown';
import { css } from "@emotion/react";
import PulseLoader from "react-spinners/PulseLoader";
import ops from "../services/hiveOps"
import {keychain, isKeychainInstalled, hasKeychainBeenUsed} from '@hiveio/keychain'
import Vote from './widgets/vote';
import Reblog from './widgets/reblog';
import Select from 'react-select-me';
import TimeAgo from 'timeago-react';
import remarkableStripper from '../utils/RemarkableStripper'
import sanitize from 'sanitize-html';
import {htmlDecode} from '../utils/htmlDecode'
import Img from 'react-cloudinary-lazy-image'
import { proxifyImageUrl } from "../utils/ProxifyUrl";
import { Row, Col, Card, notification, Menu, Radio, Tooltip } from 'antd';
import ShowCommentCount from "./widgets/commentCount";

// IMPORTANT If you want to provide default styles you have to import them
import 'react-select-me/lib/ReactSelectMe.css';
import '../home.css';
import adHype from '../assets/ad_hype.png'
import noImageFiller from '../assets/noImageFiller.png'

import { detect, detectAll } from '@ocelotbot/tinyld'

const postOptions = [
    { value: 'trending', label: 'Top Posts' },
    //{ value: 'hot', label: 'Best Posts' },
    { value: 'new', label: 'Most Recent' },
    //{ value: 'feed', label: 'Feed' }
];

const categoryOptions = [
    { value: 'all', label: 'All Posts' },
    { value: 'science', label: 'Science' },
    { value: 'physics', label: 'Physics' },
    { value: 'biology', label: 'Biology' },
    { value: 'chemistry', label: 'Chemistry' },
    { value: 'geology', label: 'Geology' },
    { value: 'technology', label: 'Technology' },
    { value: 'engineering', label: 'Engineering' },
    { value: 'mathematics', label: 'Mathematics' },
    { value: 'economics', label: 'Economics' }
];

const categoryOptionsNew = [
    { key: 'all', label: <small>{'All Posts'}</small> },
    { key: 'science', label: <small>{'Science'}</small> },
    { key: 'physics', label: <small>{'Physics'}</small> },
    { key: 'biology', label: <small>{'Biology'}</small> },
    { key: 'chemistry', label: <small>{'Chemistry'}</small> },
    { key: 'geology', label: <small>{'Geology'}</small> },
    { key: 'technology', label: <small>{'Technology'}</small> },
    { key: 'engineering', label: <small>{'Engineering'}</small> },
    { key: 'mathematics', label: <small>{'Mathematics'}</small> },
    { key: 'economics', label: <small>{'Economics'}</small> }
];


const hive = require("@hiveio/hive-js")
let md = new MarkdownIt()
const turndownService = new TurndownService()



// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

let token = localStorage.getItem('token')
let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null

function imageExists(image_url){

    var http = new XMLHttpRequest();
  
    http.open('HEAD', image_url, false);
    http.send();
  
    return http.status !== 404;
  
}


function Posts() {
    const [current, setCurrent] = React.useState('all');
    let [middlePosts, setMiddlePosts] = React.useState([]);
    let [popularCommunities, setSideOnePosts] = React.useState([]);
    let [popularCreators, setSideTwoPosts] = React.useState([]);
    let [posts, setPosts] = React.useState([]);
    let [newPostsArr, setPostsNew] = React.useState([]);
    let [hotPostsArr, setPostsHot] = React.useState([]);
    let [postsClass, setPostsClass] =  React.useState({
        value: 'hot'
    })
    let [loading, setLoading] = React.useState(true);
    let [color, setColor] = React.useState('#1b1c1d');
    let [coverImage, setCoverImage] = React.useState('/img/default_avatar.png')
    let trendingPosts = []
    let hotPosts = []
    let newPosts = []

    async function sortTrendingContents(a) {
        let postImgHeight = '300px'
        
        await a.forEach(async d => {

            if (d.title.length > 0 && d.author !== undefined && d.permlink !== undefined) {
                let json = d.json_metadata
                let title = d.title
                let voted = false
                let reblogged = false

                if (loggedInUser) {
                    d.active_votes.forEach(v => {
                        if (v.voter === loggedInUser) {
                            voted = true
                        }
                    })
                }
                
                let summary = d.body
                summary = summary.replace(/(^(\n|\r|\s)*)>([\s\S]*?).*\s*/g, '');
                summary = remarkableStripper.render(summary)
                summary = sanitize(summary, { allowedTags: [] }); // remove all html, leaving text
                summary = htmlDecode(summary);
            
                // Strip any raw URLs from preview text
                summary = summary.replace(/https?:\/\/[^\s]+/g, '');
            
                // Grab only the first line (not working as expected. does rendering/sanitizing strip newlines?)
                // eslint-disable-next-line prefer-destructuring
                summary = summary.trim().split('\n')[0];
            
                if (summary.length > 200) {
                    summary = summary.substring(0, 200).trim();
            
                    // Truncate, remove the last (likely partial) word (along with random punctuation), and add ellipses
                    summary = summary
                        .substring(0, 180)
                        .trim()
                        .replace(/[,!?]?\s+[^\s]+$/, '…');
                }  
                
                let cover = noImageFiller;
                

                if (json) {
                    json = JSON.parse(json)
                    
                    await trendingPosts.push({
                        title: title,
                        permlink: d.permlink,
                        author: d.author,
                        url: d.url,
                        last_update: d.last_update,
                        cover: json.image !== undefined && json.image.length > 0 ? proxifyImageUrl(json.image[0], true) : cover,
                        category: json.tags !== undefined ? json.tags[0] : 'none',
                        postImgHeight,
                        summary: summary,
                        voted,
                        pendingPayout: d.pending_payout_value === '0.000 HBD' ? d.total_payout_value :  d.pending_payout_value,
                        reblogged,
                        replyLength: d.children
                    })
                }
            }
        });

        
    }

    async function sortHotContents (b) {
        let postImgHeight = '300px'
        
        await b.forEach(async d => {

            if (d.title.length > 0 && d.author !== undefined && d.permlink !== undefined) {
                let json = d.json_metadata
                let title = d.title

                let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null
                let voted = false
                let reblogged = false

                if (loggedInUser) {
                    d.active_votes.forEach(v => {
                        if (v.voter === loggedInUser) {
                            voted = true
                        }
                    })
                }


                

                let summary = d.body
                if (d.body.length > 100) {
                    summary = d.body.substring(0, 90)
                }
                summary = summary.replace(/<center>/g, '').replace(/<\/center>/g, '').replace(/<div className="text-justify">/g, '').replace(/<\/div>/g, '').replace(/<div className="pull-left">/g, '').replace(/<div className="pull-right">/g, '').replace(/<hr>/g, '') 
                
                let cover = noImageFiller;
                if (json) {
                    json = JSON.parse(json)
                    hotPosts.push({
                        title: title,
                        permlink: d.permlink,
                        author: d.author,
                        url: d.url,
                        last_update: d.last_update,
                        cover: json.image !== undefined && json.image.length > 0 ? proxifyImageUrl(json.image[0], true) : cover,
                        category: json.tags !== undefined ? json.tags[0] : 'none',
                        postImgHeight,
                        summary: md.render(summary),
                        voted,
                        pendingPayout: d.pending_payout_value === '0.000 HBD' ? d.total_payout_value :  d.pending_payout_value,
                        reblogged,
                        replyLength: d.children
                    })
                }
            }
        });
    }

    async function sortNewContents (c) {
        let postImgHeight = '300px'

        await c.forEach(async d => {

            if (d.title.length > 0 && d.author !== undefined && d.permlink !== undefined) {
                let json = d.json_metadata
                let title = d.title

                let loggedInUser = localStorage.getItem('username') ? localStorage.getItem('username') : null
                let voted = false
                let reblogged = false

                if (loggedInUser) {
                    d.active_votes.forEach(v => {
                        if (v.voter === loggedInUser) {
                            voted = true
                        }
                    })
                }

                let summary = d.body
                if (d.body.length > 100) {
                    summary = d.body.substring(0, 90)
                }
                summary = summary.replace(/<center>/g, '').replace(/<\/center>/g, '').replace(/<div className="text-justify">/g, '').replace(/<\/div>/g, '').replace(/<div className="pull-left">/g, '').replace(/<div className="pull-right">/g, '').replace(/<hr>/g, '') 

                let cover = noImageFiller;
                if (json) {
                    json = JSON.parse(json)
                    await newPosts.push({
                        title: title,
                        permlink: d.permlink,
                        author: d.author,
                        url: d.url,
                        last_update: d.last_update,
                        cover: json.image !== undefined && json.image.length > 0 ? proxifyImageUrl(json.image[0], true) : cover,
                        category: json.tags !== undefined ? json.tags[0] : 'none',
                        postImgHeight,
                        summary: md.render(summary),
                        voted,
                        pendingPayout: d.pending_payout_value === '0.000 HBD' ? d.total_payout_value :  d.pending_payout_value,
                        reblogged,
                        replyLength: d.children
                    })
                }
            }
        });
    }
    

    React.useEffect(() => {
        async function getPosts() {
            function shuffle(array) {
                let currentIndex = array.length,  randomIndex;
              
                // While there remain elements to shuffle.
                while (currentIndex != 0) {
              
                  // Pick a remaining element.
                  randomIndex = Math.floor(Math.random() * currentIndex);
                  currentIndex--;
              
                  // And swap it with the current element.
                  [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
                }
              
                return array;
            }

            try {
                console.log('loading')

                let getTrending = await ops.getTrendingOpCustom();
                console.log('loading trending', getTrending)

                let getHot = await ops.getHotOpCustom();
                console.log('loading hot', getHot)

                let getNew = await ops.getNewOpCustom();
                console.log('loading new', getNew)

                await sortTrendingContents(getTrending);
                await sortHotContents(getHot);
                await sortNewContents(getNew);

                const trendingPostsUniq = [...new Set(trendingPosts)]
                const hotPostsUniq = [...new Set(hotPosts)]
                const newPostsUniq = [...new Set(newPosts)]

                setPosts(trendingPostsUniq)
                setMiddlePosts(hotPostsUniq)
                setPostsNew(newPostsUniq)
                setPostsHot(hotPostsUniq)
                document.getElementById('hideOnLoad').style.display = 'none'
            } catch (error) {
                console.log(error)
            }

        }


        getPosts()
    }, []);

    async function reloadContent (selectedOption) {
        setMiddlePosts([])
        setPostsClass({value: selectedOption.target.value})

        if (selectedOption.target.value === 'hot') {
            setMiddlePosts(hotPostsArr)
        }
        if (selectedOption.target.value === 'new') {
            setMiddlePosts(newPostsArr)
        }
        if (selectedOption.target.value === 'trending') {
            setMiddlePosts(posts)
        }

        if (selectedOption.target.value === 'feed') {
            const feed = await ops.getUserFeed(loggedInUser)
            console.log(feed)

            setMiddlePosts(posts)
        }

        document.getElementById('hideOnLoad').style.display = 'none'
    }

    const getSingleTag = async (tag) => {
        console.log('tag', tag.target.value)
        try {

            if (tag.target.value === 'all') {
                
                let getTrending = await ops.getTrendingOpCustom();
                console.log('loading trending', getTrending)
    
                let getHot = await ops.getHotOpCustom();
                console.log('loading hot', getHot)
    
                let getNew = await ops.getNewOpCustom();
                console.log('loading new', getNew)
    
                await sortTrendingContents(getTrending);
                await sortHotContents(getHot);
                await sortNewContents(getNew);
    
                const trendingPostsUniq = [...new Set(trendingPosts)]
                const hotPostsUniq = [...new Set(hotPosts)]
                const newPostsUniq = [...new Set(newPosts)]
    
                setPosts(trendingPostsUniq)
                setMiddlePosts(hotPostsUniq)
                setPostsNew(newPostsUniq)
                setPostsHot(hotPostsUniq)
                setPostsClass({value: 'hot'})
                document.getElementById('hideOnLoad').style.display = 'none'
            } else {
                console.log('loading')
                let getTrending = await ops.getTrendingOp(tag.target.value);
                console.log('loading 2')
                let getHot = await ops.getHotOp(tag.target.value);
                console.log('loading 3')
                let getNew = await ops.getNewOp(tag.target.value);
    
                await sortTrendingContents(getTrending);
                await sortHotContents(getHot);
                await sortNewContents(getNew);
    
                const trendingPostsUniq = [...new Set(trendingPosts)]
                const hotPostsUniq = [...new Set(hotPosts)]
                const newPostsUniq = [...new Set(newPosts)]
    
                setPosts(trendingPostsUniq)
                setMiddlePosts(hotPostsUniq)
                setPostsNew(newPostsUniq)
                setPostsHot(hotPostsUniq)
                setPostsClass({value: 'hot'})
                document.getElementById('hideOnLoad').style.display = 'none'
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const { selectedOption } = postsClass

    function createMarkup(params) {
        return {__html: params};
    }

    return (
        <div className="posts" id="page-content">
            <div style={{textAlign: 'center', border: '1pc solid black', borderRadius: '1rem', padding: '1.5rem', width: '90%', margin: 'auto'}}>Site is up for sale: <b>Mguag93 on Telegram, from Qanda Sensei</b></div>
            <div className="row trending-posts">
                <div className="col-lg-9 col-sm-12 col-md-9">
                    <Menu onClick={(e) => {
                        document.getElementById('hideOnLoad').style.display = 'block'
                        notification.open({
                            //message: 'Notification Title',
                            description:
                            'Loading',
                            onClick: () => {
                            console.log('Notification Clicked!');
                            },
                        });
                        setCurrent(e.key)
                        const tag = {target : {value: e.key}}
                        getSingleTag(tag)
                    }} selectedKeys={[current]} mode="horizontal" items={categoryOptionsNew} />
                </div>
            
                <div className="col-lg-3 col-sm-12 col-md-3">
                    <div className="section-title ms-auto" style={{margin: '0.5em 0', textAlign: 'right'}}>
                        <Radio.Group name="radiogroup" value={postsClass.value} onChange={(e) => {
                            document.getElementById('hideOnLoad').style.display = 'block'
                            notification.open({
                                //message: 'Notification Title',
                                description:
                                'Loading',
                                onClick: () => {
                                console.log('Notification Clicked!');
                                },
                            });
                            reloadContent(e)
                        }}>
                            {postOptions.map((option) => (
                                <Radio value={option.value} onClick={() => {
                                    if (option.value === postsClass.value) {
                                        setPostsClass({value: 'hot'})
                                        reloadContent({target : {value: 'hot'}})
                                    }
                                }}><small>{option.label}</small></Radio>
                            ))}
                        </Radio.Group>
                    </div>
                </div>
                
                <div className="col-lg-12 col-sm-12 col-md-12">
                    <div className="sweet-loading text-center" id="hideOnLoad" style={{marginTop: '15%'}}>
                        <PulseLoader color={color} loading={loading} css={override} size={50} />
                    </div>
                    <div className="card-body">
                        <h5 className="new post-title"  style={{color: '#1b1c1d', textDecoration: 'none !important'}}>Posts</h5>
                    </div>
                    <div id="postsContainer">
                        <Row>
                            {middlePosts.length > 0 && middlePosts.map((post) => (
                                <Col xxl={6} xl={8} lg={8} sm={24} md={12} style={{
                                    padding: '1em',
                                    fontFamily: '"Roboto", "Montserrat", Helvetica, Arial, sans-serif'
                                }}>
                                    <div className="card mb-3 post" key={post.permlink} data-author={post.author}>
                                        <div className="card-body" style={{position: 'relative', width: '100%', overflow: 'hidden', height: '15em' }}>
                                            <img className="card-img-top" src={post.cover} alt="Card image cap" style={{overflow:'hidden', display: 'block', width: '100%', height: 'auto'}} loading="lazy" />
                                        </div>
                                        <div className="card-body row element-with-scrollbar" style={{position: 'relative', width: '100%', overflowY: 'scroll', height: '7.5em' }}>

                                            <div className="text-start col-md-3">
                                                <img id="avatar" src={`https://images.hive.blog/u/${post.author}/avatar`} style={{borderRadius: '50%'}} loading="lazy" />
                                            </div>
                                                
                                            <div className="text-start col-md-9">
                                                <h5 className="card-title post-title-wrap" style={{fontSize: '1.25em'}}><a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important', color: '#1b1c1d', textDecoration: 'none !important'}} className="post-title">{post.title}</a></h5>
                                            </div>
                                        </div>
                                        <div className="card-body row" style={{position: 'relative', width: '100%', overflow: 'hidden', height: '7.5em' }}>
                                            <p className="card-text" style={{marginTop: '5%', display: 'flex'}}>
                                                <small className="text-muted" style={{
                                                    width: '50%',
                                                    textALign: 'left'
                                                }}>
                                                    <TimeAgo
                                                        datetime={post.last_update}
                                                    />
                                                </small>

                                                <small className="text-muted" style={{
                                                    width: '50%',
                                                    textALign: 'right'
                                                }}><a href={"/p?user=" + post.author} style={{cursor: 'pointer !important', color: '#1b1c1d', textDecoration: 'none !important'}}>@{post.author}</a> </small>
                                            </p>
                                        </div>
                                        
                                        
                                        <div className="card-footer post-footer-area row" style={{backgroundColor: "#1b1c1d"}}>
                                            <Tooltip title="Vote">
                                                <div className="vote-post text-white col" id={`${post.author}${post.permlink}`} style={{display: 'inherit', paddingTop: '1%'}}>
                                                    <Vote props={{
                                                        author: post.author,
                                                        permlink: post.permlink,
                                                        voted: post.voted,
                                                        voter: loggedInUser
                                                    }} />
                                                </div>
                                            </Tooltip>

                                            <div className="view-post text-white col">
                                                <Tooltip title="View Post">
                                                    <a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important'}} className="text-white">
                                                    < Icon.Eye /></a>
                                                </Tooltip>
                                            </div>

                                            <div className="post-comments text-white col">
                                                <Tooltip title="Comments">
                                                    <a href={`/post?permlink=${post.permlink}&author=${post.author}`} style={{cursor: 'pointer !important', textDecoration: 'none', display: 'inline-flex', marginTop: '0.5vh'}} className="text-white">< Icon.Chat /><small>{post.replyLength}</small></a>
                                                </Tooltip>
                                            </div>
                                            
                                            <Tooltip title="Reblog">
                                                <div className="reblog-post text-white col">
                                                    <Reblog props={{
                                                        author: post.author,
                                                        permlink: post.permlink,
                                                        reblogged: post.reblogged,
                                                        username: loggedInUser
                                                    }} />
                                                </div>
                                            </Tooltip>

                                            <div className="pending-payout text-white col" style={{fontSize: '10px', display: 'inline-flex', paddingTop: '1%'}}>
                                                <Tooltip title="Payout">
                                                    <b>{post.pendingPayout}</b>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}

                            {middlePosts.length === 0 && 
                                <div className="col-lg-12 col-md-12 col-sm-12 post">
                                    <div className="card mb-3" style={{margin: '5%'}}>
                                        <div className="row no-gutters">
                                            <div className="col-md-12 col-sm-12">
                                                <div className="card-body row">
                                                    <div className="text-center col-md-12">
                                                        <h5 className="card-title post-title-wrap">No posts available in this category</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Row>
                        
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Posts;